#ending-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.ending-logo-container {
  max-width: 100px;
  margin: auto;
  padding: 65px 0px;
}

.ending-content-container {
  max-width: 600px;
  margin: auto;
  padding: 0px 20px 50px 20px
}