.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.background {
  margin-top: 60px; 
}

.wrapper {
  max-width: 800px;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(256, 256, 256, 0.8);
  border: 1px solid white;
}

.page-title {
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
  border: 1px solid #215567;
  padding: 30px 30px 30px 30px;
}

.content{

}

.content-title {
  font-weight: normal;
  display: inline-block;
  margin-top: 60px;
  margin-bottom: 0px;
}

.back-button {
  margin-top: 30px;
  font-size: 1.6rem;
  font-weight: normal;
  display: inline-block;
  cursor: pointer;
}

.back-button img {
  width: 30px;
  vertical-align: middle;
}

svg path {
  opacity: 0;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    padding: 30px;
  }
}