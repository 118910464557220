.Wedo3dModule {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.transition-group-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

#canvas {
  position: absolute!important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
  transform: translate(0, 20px);
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 1000ms ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: all 1000ms ease-in-out;
}