.fade {
  transition: all 500ms ease-in-out 100ms;
}

.fade-in {
  opacity: 1;  
}

.fade-out {
  opacity: 0;
}

.responsive-font {
  font-size: 18px;
}

@media only screen and (max-width: 768px) {

  .responsive-font {
    font-size: 12px;
  }

}